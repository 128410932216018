import React from "react";
import {
  GreenBadgeBase,
  BadgeImage,
  BadgeIcon,
  BadgeCopy,
  BadgeCopyHeadline,
  BadgeCopySubheadline,
} from "./green-badge.styled";

const GreenBadge = () => {
  return (
    <GreenBadgeBase>
      <BadgeImage
        src={require("../../images/xometry-go-green.svg")}
        alt="Xometry Go Green Initiative"
      />
      <BadgeIcon className="far fa-leaf badge_icon" />
      <BadgeCopy>
        <BadgeCopyHeadline>2.0M+</BadgeCopyHeadline>
        <BadgeCopySubheadline>LBS OF C02 OFFSET</BadgeCopySubheadline>
      </BadgeCopy>
    </GreenBadgeBase>
  );
};

export default GreenBadge;
