import React, { useState } from "react";
import {
  HeaderBase,
  HeaderFlexbox,
  LogoLink,
  Logo,
  DesktopLinks,
  MobileLinks,
  MobileLinkIcon,
} from "./header.styled";
import { HeaderCtAsNodeFragment, Scalars } from "../../types/graphql";
import HeaderLinks from "../header-links/header-links";
import { IStructuredHeaderLinksData } from "../header-links/pure-header-links";
import HeaderCTAs from "../header-ctas/header-ctas";
import { Dropover } from "@xometry/xometry_loft";
import SignInDropdown from "../sign-in-dropdown/sign-in-dropdown";
import {
  SignInLink,
  SignInContainer,
  DropoverContainer,
  DropoverContent,
} from "./header.styled";
import HeaderAccordion from "../header-accordion/header-accordion";
import image from "../../images/xometry-logo.png";

export interface IHeaderProps {
  headerLinksUID: Scalars["ID"];
  headerCTAsUID: Scalars["ID"];
}

// The header component reders the logo, drawers, links,
// and ctas all in one component. Data for the header
// links and header ctas are sourced within their own
// respective components, but are set as state in this
// component so that they can be passed down to the header
// accordion.
const Header = (props: IHeaderProps) => {
  const noLinks = props.headerLinksUID === "no-links";
  const [isAccordionOpen, setAccordionOpen] = useState(false);

  // State variables for child component data.
  // Values for these are set by passing the set
  // function to the child.
  const [
    headerLinksData,
    setHeaderLinksData,
  ] = useState<IStructuredHeaderLinksData | null>(null);
  const [
    headerCTAsData,
    setHeaderCTAsData,
  ] = useState<HeaderCtAsNodeFragment | null>(null);

  return (
    <HeaderBase>
      <HeaderFlexbox>
        {/* Logo */}
        <LogoLink href="/">
          <Logo
            alt="Xometry Logo"
            src={image}
          />
        </LogoLink>

        {/* Desktop Links */}
        {!noLinks && (
          <DesktopLinks>
            <HeaderLinks
              headerLinksUID={props.headerLinksUID || "default-links"}
              setHeaderLinksData={setHeaderLinksData}
              isHeaderLinksDataSet={headerCTAsData ? true : false}
            />
          </DesktopLinks>
        )}

        {/* Desktop CTAs  */}
        <HeaderCTAs
          headerCTAsUID={props.headerCTAsUID || "default-ctas"}
          setHeaderCTAsData={setHeaderCTAsData}
        />

        {/* Mobile Header Toggle */}
        {!noLinks && (
          <MobileLinks
            tabIndex={0}
            onClick={() => setAccordionOpen(!isAccordionOpen)}
            onKeyPress={() => setAccordionOpen(!isAccordionOpen)}
          >
            {isAccordionOpen ? (
              <MobileLinkIcon className="fa fa-times"></MobileLinkIcon>
            ) : (
              <MobileLinkIcon className="fa fa-bars"></MobileLinkIcon>
            )}
          </MobileLinks>
        )}
      </HeaderFlexbox>

      {/* Accordion Header for Mobile */}
      {isAccordionOpen && (
        <HeaderAccordion
          headerCTAs={headerCTAsData}
          headerLinks={headerLinksData}
        />
      )}
    </HeaderBase>
  );
};

export default Header;
