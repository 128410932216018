import React from "react";
import {
  SimpleFooterBase,
  FooterLogo,
  FooterCopyWrapper,
  FooterCopy,
} from "./simple-footer.styled";
import footerLogo from "../../images/xometry-logo-light.png";

const SimpleFooter = () => {
  return (
    <SimpleFooterBase>
      <FooterLogo
        alt="Xometry Logo"
        src={footerLogo}
      />
      <FooterCopyWrapper>
        <FooterCopy>ITAR Registered</FooterCopy>
        <FooterCopy>
          © {new Date().getFullYear()} Xometry, All Rights Reserved
        </FooterCopy>
      </FooterCopyWrapper>
    </SimpleFooterBase>
  );
};

export default SimpleFooter;
