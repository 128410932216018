import { theme as loftTheme } from "@xometry/xometry_loft";

const colors = {
  ...loftTheme.colors,
};

const breakpoints = [0, 576, 768, 992, 1200];

const largerThan = {
  extraSmall: `@media (min-width: ${breakpoints[0] + 1}px)`,
  small: `@media (min-width: ${breakpoints[1] + 1}px)`,
  medium: `@media (min-width: ${breakpoints[2] + 1}px)`,
  large: `@media (min-width: ${breakpoints[3] + 1}px)`,
  extraLarge: `@media (min-width: ${breakpoints[4] + 1}px)`,
};

const smallerThan = {
  small: `@media (max-width: ${breakpoints[1]}px)`,
  medium: `@media (max-width: ${breakpoints[2]}px)`,
  large: `@media (max-width: ${breakpoints[3]}px)`,
  extraLarge: `@media (max-width: ${breakpoints[4]}px)`,
};

const maxWidth = "1200px";

const fonts = '"Open Sans", "Helvetica", "Arial", "sans-serif"';

const fontSizes = [...loftTheme.fontSizes];

const lineHeights = [...loftTheme.lineHeights];

const space = [...loftTheme.space];

const theme = {
  colors,
  fonts,
  maxWidth,
  breakpoints,
  largerThan: largerThan,
  smallerThan: smallerThan,
  fontSizes,
  lineHeights,
  space,
};

export default theme;
