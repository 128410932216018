import React from "react";
import {
  FooterBackground,
  FooterContent,
  FooterColumns,
  FooterColumn,
  FooterColumnHeader,
  FooterColumnLinks,
  FooterColumnLink,
  FooterRowLinks,
  FooterRowLink,
  FooterRowBottomLink,
  FooterRowBottom,
  StyledLink,
} from "./footer.styled";
import GreenBadge from "../green-badge/green-badge";
import { StaticQuery, graphql } from "gatsby";
import {
  FooterQuery,
  Prismic_FooterColumn_Text_1,
  Prismic_FooterColumn_Text_2,
  Prismic_FooterColumn_Text_3,
} from "../../types/graphql";
import { htmlSerializer } from "../../utils/htmlSerializer";
import { RichText } from "prismic-reactjs";
import footerLogo from "../../images/xometry-logo-light.png";

interface IFooterProps {
  data: FooterQuery;
}

function FooterSocial(props: {
  href: string;
  faId: string;
  ariaLabel: string;
}) {
  return (
    <a
      id={props.faId}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={"Follow Xometry on " + props.ariaLabel}
      style={{
        color: `#fff`,
        margin: `10px 5%`,
        display: `inline-block`,
      }}
    >
      <i className={"fab fa-" + props.faId + " fa-2x"}></i>
    </a>
  );
}

export const Footer = (props: IFooterProps) => {
  const footer = props.data.prismic.allFooters.edges?.find(() => true)?.node;

  function renderColumnLinks(
    title: string | null | undefined,
    items:
      | Pick<
          | Prismic_FooterColumn_Text_1
          | Prismic_FooterColumn_Text_2
          | Prismic_FooterColumn_Text_3,
          "column_item"
        >[]
      | undefined
      | null
  ) {
    return (
      <FooterColumn>
        {title && <FooterColumnHeader>{title}</FooterColumnHeader>}
        {items && items.length > 0 && (
          <FooterColumnLinks>
            {items.map((link, index) => {
              return (
                link.column_item && (
                  <FooterColumnLink key={index}>
                    <RichText
                      render={link.column_item}
                      htmlSerializer={htmlSerializer}
                    />
                  </FooterColumnLink>
                )
              );
            })}
          </FooterColumnLinks>
        )}
      </FooterColumn>
    );
  }

  return (
    (footer && (
      <FooterBackground>
        <FooterContent>
          <FooterColumns>
            {renderColumnLinks(footer.column_title_1, footer.column_text_1)}
            {renderColumnLinks(footer.column_title_2, footer.column_text_2)}
            {renderColumnLinks(footer.column_title_3, footer.column_text_3)}
          </FooterColumns>
          <StyledLink
            to={"/xometry-go-green"}
            style={{ textDecoration: "none" }}
          >
            <GreenBadge />
          </StyledLink>
          {footer.legal_links && footer.legal_links.length > 0 && (
            <FooterRowLinks>
              {footer.legal_links.map((link, index) => {
                return (
                  link.legal_link && (
                    <FooterRowLink key={index}>
                      <RichText
                        render={link.legal_link}
                        htmlSerializer={htmlSerializer}
                      />
                    </FooterRowLink>
                  )
                );
              })}
            </FooterRowLinks>
          )}
          <hr />
          <FooterRowBottom>
            <FooterRowBottomLink>
              <a id="footer-home-link" href="/">
                <img
                  src={footerLogo}
                  alt="Xometry"
                  style={{
                    width: `180px`,
                  }}
                />
              </a>
            </FooterRowBottomLink>
            <FooterRowBottomLink>
              <span>
                © {new Date().getFullYear()} Xometry, All Rights Reserved
              </span>
            </FooterRowBottomLink>
            <FooterRowBottomLink>
              <FooterSocial
                href="https://www.facebook.com/xometry"
                faId="facebook"
                ariaLabel="Facebook"
              />
              <FooterSocial
                href="https://www.twitter.com/xometry"
                faId="twitter"
                ariaLabel="Twitter"
              />
              <FooterSocial
                href="https://www.instagram.com/xometry"
                faId="instagram"
                ariaLabel="Instagram"
              />
              <FooterSocial
                href="https://www.youtube.com/channel/UCF3PKELz1eWO3bdxVLIYN7g"
                faId="youtube"
                ariaLabel="YouTube"
              />
              <FooterSocial
                href="https://www.linkedin.com/company/xometry"
                faId="linkedin-in"
                ariaLabel="LinkedIn"
              />
            </FooterRowBottomLink>
          </FooterRowBottom>
        </FooterContent>
      </FooterBackground>
    )) || <></>
  );
};

export default function FooterRender() {
  return (
    <StaticQuery
      query={`${graphql`
        query Footer {
          prismic {
            allFooters {
              edges {
                node {
                  column_text_1 {
                    column_item
                  }
                  column_text_2 {
                    column_item
                  }
                  column_text_3 {
                    column_item
                  }
                  column_title_1
                  column_title_2
                  column_title_3
                  legal_links {
                    legal_link
                  }
                }
              }
            }
          }
        }
      `}`}
      render={(data) => <Footer data={data} />}
    />
  );
}
