import styled from "@emotion/styled";
import { Link } from "gatsby";

export const FooterBackground = styled.footer`
  background-color: #253842;
  padding: 20px 5%;
`;

export const FooterContent = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`;

export const FooterColumns = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const FooterColumn = styled.div`
  flex: 1 0 33.3333%;
  width: auto;
  min-width: 200px;
`;

export const FooterColumnHeader = styled.p`
  color: #959fa4;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 1.6px;
  line-height: 1.25rem;
`;

export const FooterColumnLinks = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: -40px;
  list-style: none;
`;

export const FooterColumnLink = styled.li`
  flex: 0 0 50%;
  a {
    line-height: 28px;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    :hover {
      text-decoration: underline;
      color: #fff;
    }
  }
  color: #fff;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
`;

export const FooterRowLinks = styled.ul`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -40px;
  text-align: center;
  color: #d8dee0;
`;

export const FooterRowLink = styled.li`
  flex: 1 0 auto;
  width: auto;
  margin: 0 5px;
  padding: 4px 2px;

  a {
    color: #d8dee0;
    text-decoration: none;
    font-size: 14px;
    :hover {
      text-decoration: underline;
      color: #d8dee0;
    }
  }
`;

export const FooterRowBottom = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  color: #d8dee0;
  align-items: center;
`;

export const FooterRowBottomLink = styled.div`
  flex: 1 0 auto;
  margin: 10px 0;
  font-size: 16px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
