import styled from "@emotion/styled";
import { css } from "@emotion/core";
import theme from "../../styles/theme";

interface IContentProps {
  isGuttered: boolean;
}

export const GlobalCss = css`
  html {
    font-family: ${theme.fonts};

    ${theme.largerThan.small} {
      font-size: 133.33333% !important;
      line-height: 133.33333% !important;
    }
  }

  body {
    margin: 0;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.darkBlues[100]};
    font-weight: 400;
    margin-block-start: 8px;
    margin-block-end: 20px;

    ${theme.smallerThan.small} {
      margin-block-end: 8px;
    }
  }

  h1 {
    font-size: 2.25rem;
    line-height: 2.625rem;
  }

  h2 {
    font-size: 1.6875rem;
    line-height: 2.0625rem;
  }

  h3 {
    font-size: 1.3125rem;
    line-height: 1.6875rem;
  }

  h4 {
    font-size: 1rem;
    line-height: 1.3125rem;
  }

  h5 {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  h6 {
    font-size: 0.625rem;
    line-height: 1rem;
  }
`;

export const ContentBackground = styled.main`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`;

export const SimpleContentBackground = styled.main`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`;

export const HeaderBase = styled.main`
  width: 100%;
  z-index: 9999;
  position: sticky;
  top: 0;
`;

export const Content = styled.div<IContentProps>`
  ${(props) => {
    if (props.isGuttered)
      return `
      max-width: ${theme.maxWidth};
      padding: 2.8125rem 1.0875rem;
    `;
  }}

  margin: 0 auto;
`;
