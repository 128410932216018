import React from "react";
import { HeaderCtAsNodeFragment } from "../../types/graphql";
import { buildUrl } from "../../utils/environment-urls";
import { IStructuredHeaderLinksData } from "../header-links/pure-header-links";
import {
  StyledAccordion,
  AccordionHeader,
  AccordionContentSubmenuHeader,
  AccordionContentSubmenuRow,
  AccordionContentSubmenuRowLink,
} from "./header-accordion.styled";
import { AccordionMenu as Accordion } from "@xometry/xometry_loft";
import { htmlSerializer } from "../../utils/htmlSerializer";
import { RichText } from "prismic-reactjs";

interface IHeaderAccordionProps {
  headerLinks: IStructuredHeaderLinksData | null;
  headerCTAs: HeaderCtAsNodeFragment | null;
}

// The header accordion is a mobile version of the header
// that takes in both header links and header cta data and
// renders both within the same component. Data for the
// links (<HeaderLinks/>) and ctas (<HeaderCTAs) are
// sourced within their own respective components
// and passed in to the accordion from their
// shared parent component the <Header/>
const HeaderAccordion = (props: IHeaderAccordionProps) => {
  const { headerLinks, headerCTAs } = props;
  const drawers = headerLinks?.drawers;
  const pageLinks = headerLinks?.pageLinks;
  const ctas = headerCTAs?.ctas;

  return (
    (drawers && ctas && (
      <StyledAccordion>
        {drawers.map((drawer, index) => {
          return [
            drawer.title && (
              <AccordionHeader key={index}>{drawer.title}</AccordionHeader>
            ),
            <Accordion.Content>
              {drawer.columns.map((column, index) => {
                const header = column.primary?.column_title && (
                  <AccordionContentSubmenuHeader key={0}>
                    {column.primary?.column_title}
                  </AccordionContentSubmenuHeader>
                );

                const links = column.fields?.map(({ link }, index) => {
                  return (
                    link && (
                      <AccordionContentSubmenuRow key={index + 1} tabIndex={-1}>
                        <AccordionContentSubmenuRowLink>
                          <RichText
                            render={link}
                            htmlSerializer={htmlSerializer}
                          />
                        </AccordionContentSubmenuRowLink>
                      </AccordionContentSubmenuRow>
                    )
                  );
                });

                header && links?.unshift(header);
                return (
                  links && (
                    <Accordion.Content.Submenu key={index}>
                      {links}
                    </Accordion.Content.Submenu>
                  )
                );
              })}
            </Accordion.Content>,
          ];
        })}
        {pageLinks?.map(
          (pageLink, index) =>
            pageLink.link_text &&
            pageLink.link?.url && (
              <AccordionHeader
                isExpandable={false}
                key={index}
                onClick={() =>
                  pageLink.link?.url
                    ? (window.location.href = buildUrl(pageLink.link.url))
                    : null
                }
              >
                {pageLink.link_text}
              </AccordionHeader>
            )
        )}
        {ctas?.map(
          (cta, index) =>
            cta.cta_text &&
            cta.cta_action && (
              <AccordionHeader
                key={index}
                isExpandable={false}
                onClick={() => {
                  switch (cta.cta_action) {
                    case "Launch Drift Bot":
                      cta.drift_bot_interaction_id &&
                        window.drift.api.startInteraction({
                          interactionId: cta.drift_bot_interaction_id,
                        });
                      break;
                    case "Open URL":
                      cta.cta_link
                        ? (window.location.href = buildUrl(cta.cta_link.url))
                        : null;
                      break;
                  }
                }}
              >
                {cta.cta_text}
              </AccordionHeader>
            )
        )}
      </StyledAccordion>
    )) || <></>
  );
};

export default HeaderAccordion;
