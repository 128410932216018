import React from "react";
import { Elements } from "prismic-reactjs";
import "./htmlSerializer.css";
import { Link } from "@xometry/xometry_loft";
import { buildUrl } from "./environment-urls";

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
export const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.paragraph:
      if (element.text == "") {
        return React.createElement("br", propsWithUniqueKey({}, key));
      }

      return React.createElement(
        "p",
        propsWithUniqueKey({ className: "paragraph" }, key),
        children
      );
    case Elements.hyperlink:
      const url = buildUrl(element.data.url);
      return (
        <Link key={key} href={url}>
          {content}
        </Link>
      );
    case Elements.strong:
      return React.createElement(
        "strong",
        propsWithUniqueKey({}, key),
        children
      );
  }
};
