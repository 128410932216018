export function track(eventName: string, props: {} = {}) {
  let eventProps = {
    affiliation: "marketing",
    page_url: typeof window !== `undefined` ? window.location.href : null,
    ...props,
  };

  typeof window !== `undefined` &&
    window.analytics &&
    window.analytics.track(eventName, eventProps);
}
