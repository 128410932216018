import styled from "@emotion/styled";
import theme from "../../styles/theme";
import { Dropover } from "@xometry/xometry_loft";

export const HeaderBase = styled.header`
  background-color: #ffffff;
  border-bottom: 1px solid #cad8df;
`;

export const HeaderFlexbox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3% 0 5%;
  height: 84px;
  ${theme.largerThan.extraLarge} {
    justify-content: space-between;
  }
`;

export const LogoLink = styled.a`
  height: 35px;
  margin-right: auto;

  ${theme.smallerThan.small} {
    height: 24px;
  }

  ${theme.largerThan.extraLarge} {
    margin-right: initial;
  }
`;

export const Logo = styled.img`
  height: 35px;
  margin-right: 28px;

  ${theme.smallerThan.small} {
    height: 24px;
    margin-right: 5px;
  }
`;

export const DesktopLinks = styled.div`
  display: none;
  ${theme.largerThan.extraLarge} {
    display: flex;
    flex: 1;
  }
`;

export const MobileLinks = styled.div`
  display: none;
  outline: none;

  ${theme.smallerThan.extraLarge} {
    display: flex;
  }
`;

export const MobileLinkIcon = styled.i`
  cursor: pointer;
  font-size: 20px;
  padding: 20px 12px 20px 24px;
  color: ${theme.colors.darkBlues[75]};

  :hover {
    color: ${theme.colors.darkBlues[100]};
  }
`;

export const SignInContainer = styled.div`
  padding: 12px 16px !important;
  margin: auto 0;
  margin-left: 12px;
  white-space: nowrap;

  ${theme.smallerThan.small} {
    padding: 12px 4px !important;
  }
`;

export const SignInLink = styled.span`
  color: #55646d;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

export const DropoverContainer = styled.div`
  margin-left: auto;
`;

export const DropoverContent = styled(Dropover.Content)`
  top: 68px !important;
  transform: translateX(-42px) !important;
  padding: 0px !important;
  border-radius: 5px;

  i {
    right: 91px !important;
  }

  ${theme.smallerThan.small} {
    top: 63px !important;
    transform: translateX(-44%) !important;

    i {
      right: 56px !important;
    }
  }
` as typeof Dropover.Content;
