import { graphql } from "gatsby";

export const headerCTAsNodeQuery = graphql`
  fragment HeaderCTAsNode on PRISMIC_Header_ctas {
    _meta {
      uid
    }
    ctas {
      cta_text
      cta_action
      cta_link {
        ... on PRISMIC__ExternalLink {
          target
          url
        }
      }
      drift_bot_interaction_id
    }
  }
`;
