import React from "react";
import Helmet from "react-helmet";
import icon from "../../images/xometry-logo-fav.png";

export interface ISeoProps {
  meta_title?: string | null | undefined;
  meta_description?: string | null | undefined;
  title?: string | null | undefined;
  description?: string | null | undefined;
  image?: string | null | undefined;
  lang?: string | null | undefined;
  robots?: string | null | undefined;
}

export const SEO = (props: ISeoProps) => {
  const defaultDescription =
    "Turbocharge the way you order custom parts with our instant quoting engine. Get DFM feedback, lead times, and pricing in a matter of clicks, not days.";
  const defaultTitle = "Xometry";
  const lang = props?.lang || "en-US";
  const title = props?.title || defaultTitle;
  const metaTitle = props?.meta_title || defaultTitle;
  const metaDescription = props?.meta_description || defaultDescription;
  const image =
    props?.image ||
    "https://images.prismic.io/xometry-foundation/5ad21ea9-065d-4c29-9587-be26ec9cc1b3_xom-logo.png?auto=compress,format";
  const description = props?.description || defaultDescription;
  const robots = props?.robots;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
    >
      <link rel="icon" href={icon} />
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={image} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@xometry" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {robots && <meta name="robots" content={robots} />}
    </Helmet>
  );
};
