import React from "react";
import { SEO, ISeoProps } from "../seo/seo";
import Header from "../header/header";
import Footer from "../footer/footer";
import SimpleFooter from "../footer/simple-footer";
import {
  Content,
  ContentBackground,
  GlobalCss,
  HeaderBase,
} from "./layout.styled";
import { Global } from "@emotion/core";
import Helmet from "react-helmet";
import PrivacyBanner from "../privacy-banner/privacy-banner";
import { Scalars } from "../../types/graphql";
import { getPreviewDocUID } from "../../utils/contentUtils";
import { IHeaderProps } from "../header/header";

interface LayoutProps {
  children: React.ReactNode;
  seo: ISeoProps;
  isGuttered: boolean;
  headerLinksUID?: Scalars["ID"];
  headerCTAsUID?: Scalars["ID"];
  simpleFooter?: boolean;
  pathContext?: {
    previewType: string;
  };
}

// Returns props for header. The header is rendered based on
// the selected content entry's uid for the header links and header ctas
// content types. These uid's are passed in from the template level. If
// uids are not passed in from the template, this function is hardcoded
//to return the default content entry uids.
const getHeaderProps = (props: LayoutProps): IHeaderProps => {
  const { headerLinksUID, headerCTAsUID } = props;

  // The header content types previews'
  // are setup to render the layout, so this logic handles passing
  // the correct header uid depending on how the layout is being used
  const previewType = props.pathContext?.previewType;

  // If headerLinksUID or headerCTAsUID are not passed in
  // from template, this default to the default content entry uids
  let result: IHeaderProps = {
    headerLinksUID: "default-links",
    headerCTAsUID: "default-ctas",
  };

  if (headerLinksUID) {
    result.headerLinksUID = headerLinksUID;
  } else if (previewType === "header_links") {
    const uid = getPreviewDocUID();
    result.headerLinksUID = uid;
  }

  if (headerCTAsUID) {
    result.headerCTAsUID = headerCTAsUID;
  } else if (previewType === "header_ctas") {
    const uid = getPreviewDocUID();
    result.headerCTAsUID = uid;
  }
  return result;
};

const Layout = (props: LayoutProps) => {
  return (
    <div>
      <Helmet>
        <noscript>The Xometry app works best with JavaScript enabled!</noscript>
      </Helmet>
      <Global styles={GlobalCss} />
      <SEO {...props.seo} />
      <HeaderBase data-smooth-scroll-header>
        <Header {...getHeaderProps(props)} />
        <PrivacyBanner />
      </HeaderBase>
      <ContentBackground>
        <Content {...props}>{props.children}</Content>
      </ContentBackground>
      {props.simpleFooter ? <SimpleFooter /> : <Footer />}
    </div>
  );
};

export default Layout;
