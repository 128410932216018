import styled from "@emotion/styled";

export const GreenBadgeBase = styled.div`
  display: flex;
  width: fit-content;
  padding: 10px 16px 10px 10px;
  color: #fafcff;
  border: 3px solid #2d8b7d;
  border-radius: 50px;
`;

export const BadgeImage = styled.img`
  height: 120px;
  width: 195px;
  margin: -30px;
`;

export const BadgeIcon = styled.i`
  align-self: center;
  margin-top: -6px;
  color: #476175;
  font-size: 28px;
  margin-right: 8px;
  line-height: 44px;
`;

export const BadgeCopy = styled.div`
  align-self: center;
  margin-top: -6px;
`;

export const BadgeCopyHeadline = styled.div`
  font-size: 20px;
  line-height: 30px;
`;

export const BadgeCopySubheadline = styled.div`
  font-size: 13px;
  font-weight: bold;
  line-height: 13px;
`;
