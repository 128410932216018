import React, { useState, useEffect } from "react";
import { PrivacyBannerBase } from "./privacy-banner.styled";
import { useCookies } from "react-cookie";

const PrivacyBanner = () => {
  const privacyCookie = "__hs_opt_out";
  const [cookies, setCookie] = useCookies([privacyCookie]);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!cookies[privacyCookie]) setShowBanner(true);
  });

  function handleAccept() {
    setShowBanner(false);
    setCookie(privacyCookie, true, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      domain: window.location.hostname.replace("www", ""),
    });
  }

  return (
    <>
      <PrivacyBannerBase
        kind="warning"
        title="Cookie Policy"
        buttonText="Accept"
        onButtonClick={handleAccept}
        showBanner={showBanner}
        data-cy="privacy-banner"
      >
        Xometry stores cookies on your computer to provide more personalized
        services to you, both on this website and through other media. By using
        this website, you consent to the cookies we use and our&nbsp;
        <a
          style={{ color: "black", textDecoration: "underline" }}
          href="/legal"
        >
          Privacy Policy
        </a>
        .
      </PrivacyBannerBase>
    </>
  );
};

export default PrivacyBanner;
