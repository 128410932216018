import styled from "@emotion/styled";
import theme from "../../styles/theme";

export const SimpleFooterBase = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 32px 10%;

  ${theme.largerThan.medium} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FooterLogo = styled.img`
  width: 159px;
  height: 35px;
  margin: 0 auto 32px auto;

  ${theme.largerThan.medium} {
    margin: initial;
  }
`;

export const FooterCopyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.largerThan.medium} {
    flex-direction: row;
  }
`;

export const FooterCopy = styled.div`
  color: #476175;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-bottom: 8px;

  ${theme.largerThan.medium} {
    line-height: 34px;
    padding: 0 16px;
    white-space: nowrap;

    &:first-of-type {
      border-right: 1px solid #979797;
    }
  }
`;
