import React, { useEffect, useRef, useState } from "react";
import {
  HeaderLinksBase,
  LinksContainer,
  DrawerTitleLink,
  HeaderLinkIcon,
  PageLink,
  Drawer,
  DrawerContent,
  DrawerColumn,
  DrawerHeader,
  DrawerLink,
} from "./header-links.styled";
import {
  Scalars,
  HeaderLinksQuery,
  Prismic_Header_LinksBody,
  Prismic_Header_LinksBodyDrawer_Button,
  Prismic_Header_LinksBodyDrawer_Column,
  HeaderLinksNodeFragment,
} from "../../types/graphql";
import { RichText } from "prismic-reactjs";
import { htmlSerializer } from "../../utils/htmlSerializer";
import { buildUrl } from "../../utils/environment-urls";

interface IPureHeaderLinksProps {
  headerLinks: HeaderLinksQuery;
  headerLinksUID: Scalars["ID"];
  setHeaderLinksData: (data: IStructuredHeaderLinksData) => void;
  isHeaderLinksDataSet: boolean;
}

export interface IDrawerData {
  title: string | null | undefined;
  columns: Prismic_Header_LinksBodyDrawer_Column[];
}

export interface IStructuredHeaderLinksData {
  drawers: IDrawerData[];
  pageLinks: HeaderLinksNodeFragment["page_links"];
}

// Function to extract array of drawer objects.
const getDrawerDataStructure = (
  slices: Prismic_Header_LinksBody[]
): IDrawerData[] => {
  let drawerData: IDrawerData[] = [];

  let i = 0;
  while (slices && i < slices.length) {
    if (slices[i].type === "drawer_button") {
      // Push a drawer object onto result array with title and empty columns array
      drawerData.push({
        title: (slices[i] as Prismic_Header_LinksBodyDrawer_Button).primary
          ?.drawer_button_text,
        columns: [],
      });
      let drawerColumns = [];
      i++;
      // Loop over all drawer column slices pushing column data into columns array
      while (i < slices.length && slices[i].type !== "drawer_button") {
        if (slices[i].type === "drawer_column")
          drawerColumns.push(
            slices[i] as Prismic_Header_LinksBodyDrawer_Column
          );
        i++;
      }
      drawerData[drawerData.length - 1].columns.push(...drawerColumns);
    }
  }

  return drawerData;
};

// This component contains all the markup and logic for the drawers
// and page links that appear on the header. Data is only passec
export const PureHeaderLinks = (props: IPureHeaderLinksProps) => {
  // Current open drawer
  const [openIndex, setOpenIndex] = useState(-1);
  const headerLinkRef: any = useRef(null);

  // Used to calcualte height of drawer for styling purposes
  const [drawerHeight, setDrawerHeight] = useState(0);
  const drawerRef: any = useRef(null);
  const drawerContentRef: any = useRef(null);

  const {
    headerLinks,
    headerLinksUID,
    setHeaderLinksData,
    isHeaderLinksDataSet,
  } = props;
  const headerEntries = headerLinks.prismic.allHeader_linkss.edges;

  // Get the data for the corresponding header entry passed in from template. If not found,
  // it defaults to the default entry which is assumed to exist.
  const selectedEntry =
    headerEntries?.find((edge) => edge?.node?._meta.uid === headerLinksUID) ||
    headerEntries?.find((edge) => edge?.node._meta.uid === `default-links`);

  const drawers = getDrawerDataStructure(
    selectedEntry?.node?.body as Prismic_Header_LinksBody[]
  );

  useEffect(() => {
    // Set the structured drawer data in state of <Header/> component
    drawers.length > 0 && !isHeaderLinksDataSet &&
      setHeaderLinksData &&
      setHeaderLinksData({
        drawers: drawers,
        pageLinks: selectedEntry?.node.page_links,
      });

    document.addEventListener("mousedown", handleOutsideClick);

    if (openIndex != -1 && drawerContentRef.current) {
      setDrawerHeight(drawerContentRef.current.clientHeight);
    }

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  // Closes drawer if user clicked outside of the header
  const handleOutsideClick = (e: MouseEvent) => {
    if (
      !headerLinkRef?.current?.contains(e.target) &&
      !drawerRef?.current?.contains(e.target)
    ) {
      setOpenIndex(-1);
    }
  };

  const handleLinkInteraction = (index: number) => {
    openIndex != index ? setOpenIndex(index) : setOpenIndex(-1);
  };

  return (
    <HeaderLinksBase>
      {/* Flexbox for the Drawer Title Buttons and Page Links */}
      <LinksContainer ref={headerLinkRef}>
        {/* Drawer Title Buttons */}
        {drawers.map((drawer, index) => {
          return (
            drawer &&
            drawer.title && (
              <DrawerTitleLink
                key={index}
                tabIndex={0}
                onClick={() => handleLinkInteraction(index)}
                onKeyPress={() => handleLinkInteraction(index)}
                isOpen={openIndex == index}
              >
                {drawer.title}
                {openIndex == index ? (
                  <HeaderLinkIcon className="fa fa-caret-up"></HeaderLinkIcon>
                ) : (
                  <HeaderLinkIcon className="fa fa-caret-down"></HeaderLinkIcon>
                )}
              </DrawerTitleLink>
            )
          );
        })}

        {/* Page Links */}
        {selectedEntry?.node.page_links?.map(
          (pageLink, index) =>
            pageLink.link_text &&
            pageLink.link?.url && (
              <PageLink
                key={index}
                href={buildUrl(pageLink.link.url)}
                target={pageLink.link.target || ""}
              >
                {pageLink.link_text}
              </PageLink>
            )
        )}
      </LinksContainer>

      {/* Drawer for desktop */}
      <Drawer
        drawerHeight={drawerHeight}
        className={openIndex != -1 ? "open" : ""}
        ref={drawerRef}
      >
        {openIndex != -1 && (
          <DrawerContent ref={drawerContentRef}>
            {drawers[openIndex].columns.map(
              (column, index) =>
                column && (
                  <DrawerColumn key={index}>
                    {column.primary?.column_title && (
                      <DrawerHeader>
                        {column.primary?.column_title}
                      </DrawerHeader>
                    )}
                    {column.fields?.map(({ link }, index) => {
                      return (
                        link && (
                          <DrawerLink key={index}>
                            <RichText
                              render={link}
                              htmlSerializer={htmlSerializer}
                            />
                          </DrawerLink>
                        )
                      );
                    })}
                  </DrawerColumn>
                )
            )}
          </DrawerContent>
        )}
      </Drawer>
    </HeaderLinksBase>
  );
};
