import styled from "@emotion/styled";
import theme from "../../styles/theme";
import { AccordionMenu as Accordion } from "@xometry/xometry_loft";

export const StyledAccordion = styled(Accordion)`
  position: absolute;
  overflow-y: auto;
  max-height: 450px;

  ${theme.largerThan.extraLarge} {
    display: none !important;
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  font-size: 14px;
  color: #55646d !important;
` as typeof Accordion.Header;

export const AccordionContentSubmenuHeader = styled(
  Accordion.Content.Submenu.Header
)`
  font-size: 12px;
`;

export const AccordionContentSubmenuRow = styled(Accordion.Content.Submenu.Row)`
  padding: 0px;
`;

export const AccordionContentSubmenuRowLink = styled.div`
  color: #253843;
  font-size: 12px;
  line-height: 20px;

  p a {
    padding: 12px 12px 12px 20px;
    color: #253843;
    display: block;

    &:hover {
      text-decoration: none;
      color: #253843;
    }
  }
`;
