import React, { useEffect } from "react";
import { HeaderCTAsBase, StyledCTA } from "./header-ctas.styled";
import {
  HeaderCtAsQuery,
  Scalars,
  Prismic__ExternalLink,
  HeaderLinksNodeFragment,
} from "../../types/graphql";

interface IPureHeaderCTAsProps {
  headerCTAs: HeaderCtAsQuery;
  headerCTAsUID: Scalars["ID"];
  setHeaderCTAsData: (data: HeaderLinksNodeFragment) => void;
}

export const PureHeaderCTAs = (props: IPureHeaderCTAsProps) => {
  const { headerCTAs, headerCTAsUID, setHeaderCTAsData } = props;
  const headerEntries = headerCTAs.prismic.allHeader_ctass.edges;

  // Get the data for the corresponding header entry passed in from template. If not found,
  // it defaults to the default entry which is assumed to exist.
  const selectedEntry =
    headerEntries?.find((edge) => edge?.node?._meta.uid === headerCTAsUID) ||
    headerEntries?.find((edge) => edge?.node._meta.uid === `default-ctas`);

  const ctas = selectedEntry?.node.ctas;

  useEffect(() => {
    // Set the selected entry data in state of <Header/> component
    selectedEntry?.node &&
      setHeaderCTAsData &&
      setHeaderCTAsData(selectedEntry?.node);
  }, [selectedEntry]);

  return (
    <HeaderCTAsBase>
      {ctas
        ?.slice(0, 2)
        .map(
          (cta, index) =>
            cta.cta_text && (
              <StyledCTA
                key={index}
                id={index % 2 === 0 ? "header-cta-0" : "header-cta-1"}
                kind={index % 2 === 0 ? "outline-blue" : "primary"}
                pageSection="header"
                ctaText={cta.cta_text}
                action={cta.cta_action}
                linkURL={(cta.cta_link as Prismic__ExternalLink)?.url}
                driftBotInteractionID={cta.drift_bot_interaction_id}
              />
            )
        )}
    </HeaderCTAsBase>
  );
};
