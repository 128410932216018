import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Scalars, HeaderLinksQuery } from "../../types/graphql";
import { headerLinksNodeQuery } from "./header-links.query";
import { withPreview } from "gatsby-source-prismic-graphql";
import {
  IStructuredHeaderLinksData,
  PureHeaderLinks,
} from "./pure-header-links";

interface IHeaderLinksProps {
  headerLinksUID: Scalars["ID"];
  setHeaderLinksData: (data: IStructuredHeaderLinksData) => void;
  isHeaderLinksDataSet: boolean;
}

const query = graphql`
  query HeaderLinks {
    prismic {
      allHeader_linkss {
        edges {
          node {
            ...HeaderLinksNode
          }
        }
      }
    }
  }
`;

// Render function for Header Links, queries Prismic for data in
// a static query and renders the PureHeaderLinks component.
export default function RenderHeaderLinks(props: IHeaderLinksProps) {
  return (
    <StaticQuery
      // Pass query as string as defined
      // in this Github issue https://bit.ly/3cgxAhX
      query={`${query}`}
      // @ts-ignore
      render={withPreview(
        (data: HeaderLinksQuery) => (
          <PureHeaderLinks
            headerLinks={data}
            headerLinksUID={props.headerLinksUID}
            setHeaderLinksData={props.setHeaderLinksData}
            isHeaderLinksDataSet={props.isHeaderLinksDataSet}
          />
        ),
        query,
        [headerLinksNodeQuery]
      )}
    />
  );
}
