import { graphql } from "gatsby";

export const headerLinksNodeQuery = graphql`
  fragment HeaderLinksNode on PRISMIC_Header_links {
    _meta {
      uid
    }
    body {
      ... on PRISMIC_Header_linksBodyDrawer_button {
        type
        primary {
          drawer_button_text
        }
      }
      ... on PRISMIC_Header_linksBodyDrawer_column {
        type
        fields {
          link
        }
        primary {
          column_title
        }
      }
    }
    page_links {
      link_text
      link {
        ... on PRISMIC__ExternalLink {
          target
          url
        }
      }
    }
  }
`;
