import { graphql, StaticQuery } from "gatsby";
import React from "react";

import {
  HeaderCtAsQuery,
  Scalars,
  HeaderLinksNodeFragment,
} from "../../types/graphql";
import { headerCTAsNodeQuery } from "./header-ctas.query";
import { withPreview } from "gatsby-source-prismic-graphql";
import { PureHeaderCTAs } from "./pure-header-ctas";

interface IHeaderCTAsProps {
  headerCTAsUID: Scalars["ID"];
  setHeaderCTAsData: (data: HeaderLinksNodeFragment) => void;
}

const query = graphql`
  query HeaderCTAs {
    prismic {
      allHeader_ctass {
        edges {
          node {
            ...HeaderCTAsNode
          }
        }
      }
    }
  }
`;

export default function RenderHeaderCTAs(props: IHeaderCTAsProps) {
  return (
    <StaticQuery
      // Pass as query as string as defined
      // in this Github issue https://bit.ly/3cgxAhX
      query={`${query}`}
      // @ts-ignore
      render={withPreview(
        (data: HeaderCtAsQuery) => (
          <PureHeaderCTAs
            headerCTAs={data}
            headerCTAsUID={props.headerCTAsUID}
            setHeaderCTAsData={props.setHeaderCTAsData}
          />
        ),
        query,
        [headerCTAsNodeQuery]
      )}
    />
  );
}
