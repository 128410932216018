import styled from "@emotion/styled";
import theme from "../../styles/theme";
import { keyframes } from "@emotion/core";

type DrawerProps = {
  className: string;
  drawerHeight: number;
};

type HeaderLinkProps = {
  isOpen: boolean;
};

export const HeaderLinksBase = styled.div``;

export const LinksContainer = styled.div`
  display: flex;
  margin-right: auto;
  align-items: center;
`;

export const DrawerTitleLink = styled.span<HeaderLinkProps>`
  color: #55646d;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  white-space: nowrap;
  text-decoration: none;
  padding: 20px 12px;
  outline: none;
  text-decoration: ${(props) => (props.isOpen ? "underline" : "none")};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
export const HeaderLinkIcon = styled.i`
  margin-left: 4px;
`;

export const PageLink = styled.a`
  color: #55646d;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  white-space: nowrap;
  text-decoration: none;
  padding: 20px 12px;

  &:hover {
    text-decoration: underline;
  }

  ${theme.smallerThan.medium} {
    display: none;
  }
`;

export const Drawer = styled.div<DrawerProps>`
  position: absolute;
  left: 0;
  box-sizing: content-box;
  width: 90%;
  background-color: #ffffff;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.24);

  height: 0;
  opacity: 0;
  transition: height 0.2s, opacity 0.2s;

  ${theme.largerThan.extraLarge} {
    padding: 20px 5% 24px;
    &.open {
      height: ${(props) => props.drawerHeight + "px"};
      opacity: 1;
    }
  }
`;

export const DrawerContent = styled.div`
  display: flex;
`;

const drawerColumnFadeIn = keyframes`
    from, 60% {
        opacity: 0;
        transform: translateY(-20px)
    }

    to {
        opacity: 1;
        transform: translateY(0px)
    }
`;

export const DrawerColumn = styled.div`
  display: flex;
  flex: 0 0 15%;
  flex-direction: column;
  font-size: 12px;
  line-height: 15px;
  animation: ${drawerColumnFadeIn} 0.4s ease-in;
`;

export const DrawerHeader = styled.div`
  color: #55646d;
  margin-bottom: 18px;
  font-weight: 700;
`;

export const DrawerLink = styled.div`
  margin: 0 8px 18px 12px;

  & a {
    color: #253843;
  }

  & p {
    color: #253843;
    font-weight: 600;
  }
`;
