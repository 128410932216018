import styled from "@emotion/styled";
import theme from "../../styles/theme";
import CTA from "../cta/cta";

export const HeaderCTAsBase = styled.div`
  display: flex;
`;

export const StyledCTA = styled(CTA)`
  padding: 12px 16px !important;
  margin: auto 0;
  margin-left: 12px;
  white-space: nowrap;

  ${theme.smallerThan.medium} {
    display: none;

    :first-of-type {
      display: initial;

      ${theme.smallerThan.small} {
        display: none;
      }
    }
  }
` as typeof CTA;
