import React from "react";
import { Button, ButtonProps } from "@xometry/xometry_loft";
import { track } from "../../utils/analytics";
import { isMobile } from "react-device-detect";
import { buildUrl } from "../../utils/environment-urls";

type CTAProps = {
  onClickFunction?: () => void;
  pageSection: string;
  ctaText: string;
  action?: string | null;
  linkURL?: string | null;
  driftBotInteractionID?: number | null;
  additionalEventProps?: {};
} & ButtonProps;

const CTA = (props: CTAProps) => {
  const eventName = "CTA Clicked";
  const {
    pageSection,
    ctaText,
    action,
    linkURL,
    driftBotInteractionID,
    additionalEventProps,
    onClickFunction,
  } = props;

  function navigateToUrl(link: string | null | undefined) {
    if (!link) {
      return;
    }
    /* 
     The quoting engine is not optimized for mobile. Until that is the case, 
     redirect the user to a Hubspot Landing page to capture their email and 
     remind them to quote on a desktop device
    */
    const regex = RegExp("^.*get.xometry.com.*$");

    if (regex.test(link) && isMobile) {
      window.location.href = "https://pages.xometry.com/remind-me-on-desktop";
    } else {
      window.location.href = buildUrl(link);
    }
  }

  return (
    <Button
      kind={props.kind}
      scale={props.scale}
      id={props.id}
      type={props.type}
      className={props.className}
      onClick={() => {
        track(eventName, {
          page_section: pageSection,
          cta_text: ctaText,
          action: action,
          link_URL: linkURL,
          drift_bot_interaction_id: driftBotInteractionID,
          ...additionalEventProps,
        });
        switch (action) {
          case "Launch Drift Bot":
            if (driftBotInteractionID)
              window.drift.api.startInteraction({
                interactionId: driftBotInteractionID,
              });
            break;
          case "Open URL":
            navigateToUrl(linkURL);
            break;
          default:
            navigateToUrl(linkURL);
        }
        if (onClickFunction) onClickFunction();
      }}
    >
      {props.ctaText}
    </Button>
  );
};

export default CTA;
